.pagination ul {
  list-style-type: none;
  display: flex;
  gap: .5rem;
}

.pagination ul li {
  border: 1px solid lightgray;
  padding: 0 .5rem;
  font-size: 1rem;
}

.pagination ul li.selected {
  background-color: #215fb6;
  border-color: #215fb6;
  color: white;
}

.pagination ul li a {
  text-decoration: none;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 0.1em 0.6em;
  margin: -0.1em -0.6em;
}