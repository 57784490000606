.find-replace-container {
    &.sticky-component {
        position: sticky;
        z-index: 1;
        top: 1px;
    }
    .accordion-button, .accordion-button:not(.collapsed) {
        background-color: #f8f9fa;
        color: #000;
    }
      
    .accordion-button::after {
        filter: invert(0);
    }
}
