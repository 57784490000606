/* Styling for the autocomplete input */
.autocomplete-wrapper {
    position: relative;
    width: 100%; /* Make input take full width */
  }
  
  .autocomplete-input {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: none;
    height: 38px; /* Adjust the height to match other inputs */
  }
  
  .autocomplete-input:focus {
    outline: none;
    border: 1px solid #007bff;
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
  }
  
  /* Dropdown list styling */
  .autocomplete-list {
    position: absolute;
    top: 45px; /* Adjust based on input height */
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    z-index: 1000;
    
    &.show-border {
        border: 1px solid #ccc;
    }
  }
  
  .autocomplete-item {
    padding: 10px 15px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .autocomplete-item:hover {
    background-color: #f0f0f0;
  }
  
  .autocomplete-item strong {
    font-weight: bold;
    color: black;
  }
  
  /* Scrollable dropdown */
  .autocomplete-list::-webkit-scrollbar {
    width: 6px;
  }
  
  .autocomplete-list::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  
  .autocomplete-item.highlighted {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  