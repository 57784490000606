* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, a {
  margin-top: 0;
  margin-bottom: 0;
}

.cursor-pointer {
  cursor: pointer;
}

